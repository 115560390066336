<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner :loading="!!loaderCount" :position="'absolute'"/>
      <h1 class="ms-otp-form-title">{{ $t('auth.otp.singInPage.title') }}</h1>
      <div class="ms-otp-caption">{{ $t('auth.otp.singInPage.description') }}</div>
      <form @submit.prevent="submitLoginRequirementsForm" id="emailStepForm" class="ms-otp-form-wrap">
        <div :class="[emailFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <div class="ms-otp-input-email-wrap">
            <input
              type="email" name="email" ref="email"
              class="ms-otp-input" id="ms-otp-input-email-sign-in"
              :placeholder="$t('auth.otp.singInPage.email.placeholder')"
              v-model="email" @focus="emailFieldErrorMessage = null"
              autocomplete="username"
            />
          </div>
          <span v-if="emailFieldErrorMessage" class="ms-otp-error-text">{{ emailFieldErrorMessage }}</span>
        </div>
        <div id="msp-captcha-box"></div>
        <div v-if="captchaFieldErrorMessage" class="ms-otp-error-field">
          <span class="ms-otp-error-text">{{ captchaFieldErrorMessage }}</span>
        </div>

        <div class="ms-otp-btn-wrap">
          <input
            type="submit"
            class="ms-otp-btn"
            id="submit"
            :value="$t('default.next.title')"
            :disabled="submitLocked"
          />
        </div>

        <input v-if="autofillType === 'ios'"
               type="password" name="password" autocomplete="password"
               style="width: 0;height: 0;border-width: 0;"/>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp" alt="login image">
    </div>
  </div>
</template>

<script>
import IconPerson from '@/components/icons/IconPerson.vue'
import { signinFlowApi } from '@/api/SigninFlowApi'
import { AuthenticationRequestType } from "@/constants/AuthenticationRequestType";
import UserDataUtils from "@/util/UserDataUtils";
import {captchaHandler} from "@/mixins/captcha-handler";
import {SigninProcessService} from "@/service/SigninProcessService";
import {SigninProcessStep} from "@/constants/SigninProcessStep";
import {mixinLoader} from "@/mixins/mixin-loader";

export default {
  name: 'RequestSignin',
  components: {
    IconPerson
  },
  mixins: [captchaHandler, mixinLoader],
  data () {
    return {
      requestType: AuthenticationRequestType.SIGNIN_INFO_REQUEST,
      errors: [],
      submitLocked: false,
      email: '',
    }
  },
  computed: {
    autofillType () {
      return this.$route.query?.autofillType
    },
    emailFieldErrorMessage: {
      get () {
        return this.getFieldError('email')
      },
      set () {
        const locationError = this.getLocationError('email')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    },
    captchaFieldErrorMessage: {
      get () {
        return this.getFieldError('captcha')
      },
      set () {
        const locationError = this.getLocationError('captcha')
        if (locationError) {
          this.errors = this.errors.filter(el => el.location !== locationError.location)
        }
      }
    }
  },
  created () {
    this.showSpinner()
    UserDataUtils.deleteEmail()
    signinFlowApi.loadStep({signinProcessStep: SigninProcessStep.REQUEST_SIGNIN})
      .then(() => {
        return this.checkCaptchaRequired()
      })
      .catch((error) => {
        this.$router.push({ path: '/' })
      })
      .finally(() => {
        this.hideSpinner()
      })

    this.signinProcessService = new SigninProcessService(
      this.$router,
      this.$store,
      [
        SigninProcessStep.AUTH_OTP,
        SigninProcessStep.AUTH_PASSWORD,
        SigninProcessStep.AUTH_OIDC,
      ]
    )
  },
  mounted () {
    if (this.$route.query?.email) {
      this.email = this.$route.query.email
      this.$router.replace({ query: { ...this.$route.query, email: undefined } })
    }
    if (this.$refs.email) {
      this.$refs.email.focus();
    }
  },
  methods: {
    submitLoginRequirementsForm () {
      if (!this.captchaRequired) {
        this.submitForm()
      }
    },
    submitForm () {
      if (this.submitLocked || (this.captchaRequired && !this.captchaRendered)) {
        return
      }
      this.submitLocked = true

      this.showSpinner()
      this.prepareSubmitParams()
        .then((params) => {
          signinFlowApi.requestSignin(params)
            .then((response) => {
              UserDataUtils.setEmail(this.email)
              this.signinProcessService.pushToNextSigninStep(response.data)
            })
            .catch((error) => {
              this.errors = error.response.data.errors
              this.rerenderCaptcha().finally(() => {
                this.submitLocked = false
                this.hideSpinner()
              })
            })
        })
    },
    prepareSubmitParams () {
      const params = {
        email: this.email,
        withOidc: true
      }

      const promises = []
      if (this.captchaRequired) {
        promises.push(this.getSubmitToken())
        promises.push(this.getValidationToken())

        return Promise.all(promises)
          .then(([captchaSubmitToken, captchaValidationToken]) => {
            return {
              ...params,
              captchaSubmitToken,
              captchaValidationToken
            }
          })
      }
      return new Promise((resolve, reject) => {
        resolve(params)
      })
    },
    getLocationError (field) {
      if (!this.errors || !field) {
        return null
      }

      return this.errors.find((el) => {
        if (el.location === field) {
          return el
        }
      })
    },
    getFieldError (field) {
      if (!this.errors) {
        return null
      }

      const it = this.errors.find((el) => {
        if (el.location === field) {
          return true
        }
      })
      if (it) {
        return this.$t(it.userMessageCode)
      }
    },
    showSpinner () {
      this.$emit('loading-increment')
    },
    hideSpinner () {
      this.$emit('loading-decrement')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/helpers/all-helpers';

.ms-otp-error-field .ms-otp-input-email-wrap label {
  border-right: 1px solid $error-color;
}
</style>
